import { upperFirst, camelCase } from 'lodash'
const reqComponent = require.context('./', true, /\.(vue)$/)
export default {
  install: (app) => {
    reqComponent.keys().forEach(file => {
      if (file === './index.js') return
      const componentConfig = reqComponent(file)
      const componentName = upperFirst(
          camelCase(
              file.split('/').pop().replace(/\.\w+$/, '')
          )
      )
      let com = componentConfig.default || componentConfig
      console.log(componentName)
      app.component(componentName, com)
    })
  }
}
