import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import './assets/g-reset.css'
import './assets/vant-reset.css'
import { VueMasonryPlugin } from "vue-masonry";
import { createPinia } from 'pinia'
const pinia = createPinia()

import piniaPersist from 'pinia-plugin-persist'
import components from './components'

pinia.use(piniaPersist)


router.beforeEach((to, from, next) => {
    const title = to.meta.title
    if (title) {
        document.title = title
    }
    next()
})

const app = createApp(App)
import {installVantUI} from './components/vantUI'
installVantUI(app)
app.use(pinia)
app.use(components)
app.use(router).mount('#app')
app.use(VueMasonryPlugin)
